import './App.css';

function Top() {
    return (
      <div id="top">
        <div className="center width">
          <img src="logo-white.png" id="logo" />
        </div>
      </div>
    )
}

export default Top;