import './App.css';

function Footer() {
  return (
    <div id='footer' className="center width">
    <div id='footer-content'>
        <div className="row">
        <div className="column">
            <p>© Sopht AB <Year /></p>
            <p>
                Org.nr: 559342-9938<br/>
                Tullinge
            </p>
        </div>
        <div className="column">
            <p>
                <a href='https://www.sopht.se'>www.sopht.se</a>
            </p>
            <p>
                <a href='https://www.linkedin.com/in/jwilnerzon/' target='_blank'>Connect</a>
            </p>
        </div>
        <div className="column">
            <p>Holds approval for F-tax</p>
            <p>Bankgiro: 5776-7840</p>
        </div>
        </div>
        
    </div>
    </div>
  )
}

function Year() {
  return new Date().toLocaleDateString('sv-SE').substring(0,4);
}

export default Footer;