import './App.css';
import Footer from './Footer';
import Page from './Page';
import Top from './Top';

function App() {
  return (
    <>
      <Top />
      <Page />
      <Footer />
    </>
  )
}

export default App;
