import './App.css';
import { useState, useEffect } from 'react';

function Page() {
  return (
    <div id='page' className="center width">
        <div className='content'>
            <Header />
            <Abstract />
            <Assignment />
        </div>
    </div>
  )
}

async function getData(url, setData) {
  await fetch(url)
    .then(response => response.json())
    .then(json => setData(json))
    .catch(error => console.error(error));
}

function AssignmentTags(args) {
  
  const [tags, setTags] = useState(null);

  useEffect(() => {
    if (args.id) {
      const url = 'https://api.sopht.se/?rest_route=/wp/v2/tags&per_page=100&post=' + args.id;
      getData(url, setTags);
    }
  }, [args]);


  return (
    <div> { tags ? tags.map((tag) => <div className='tag' key={tag.name}>{tag.name}</div>) : <div>Loading tags...</div>}</div>
  );
    
}

function Assignment() {
  const [assignments, setAssignments] = useState(null);
  
  useEffect(() => {
    getData('https://api.sopht.se/?rest_route=/wp/v2/posts&categories=3', setAssignments);
  }, []);
  
  return (
    <div>
      <h2>Current Assignment</h2>
      <h3 dangerouslySetInnerHTML={{ __html: assignments ? assignments[0].title.rendered : 'Loading assignment...' }}/>
      <div dangerouslySetInnerHTML={{ __html: assignments ? assignments[0].content.rendered : '...' }}/>
      <AssignmentTags id={assignments ? assignments[0].id : null}/>
      <p><a href='https://linkedin.com/in/jwilnerzon' target='_blank'>All assignments</a></p>
    </div>
  );
}

function Abstract() {
  const [abstract, setAbstract] = useState(null)
  
  useEffect(() => {
    getData('https://api.sopht.se/?rest_route=/wp/v2/pages/9', setAbstract);
  }, []);
  
  return (
    <div>
      <h2>{ abstract ? abstract.title.rendered : 'Loading abstract...'}</h2>
      <img src='avatar.jpg' id='avatar' />
      <div dangerouslySetInnerHTML={{ __html: abstract ? abstract.content.rendered : 'Loading...' }}/>
    </div>
  );
}

function Header() {
  return <h1>Senior Software Engineer Consultant</h1>
}

export default Page;